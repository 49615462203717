import { TemperatureType } from '../models/TemperatureType';
import { BaseService } from './BaseService';

interface IParaphraseRequest {
    text: string;
    style: string;
    chatId: string;
    temperature: TemperatureType;
    file?: string;
}

interface IDownloadParaphrasedDocRequest {
    paraphrasedText: string;
    file: string;
}

interface IParaphraseResponse {
    file?: Blob;
    paraphrasedText: string;
}

export class ParaphraserService extends BaseService {
    public getParaphrasedText = async (
        accessToken: string,
        text: string,
        style: string,
        chatId: string,
        temperature: TemperatureType,
        file?: File,
    ): Promise<IParaphraseResponse | undefined> => {
        try {
            const base64EncodedFile = file ? await ParaphraserService.encodeFileToBase64(file) : undefined;
            const body: IParaphraseRequest = {
                text,
                style,
                chatId,
                temperature,
                file: base64EncodedFile,
            };
            return await this.getResponseAsync<IParaphraseResponse>(
                {
                    commandPath: `Paraphrase`,
                    method: 'POST',
                    body: body,
                },
                accessToken,
            );
        } catch (error) {
            console.error(`Error getting paraphrased response:`, error);
            return undefined;
        }
    };

    public downloadParaphrasedDoc = async (paraphrasedText: string, file: File, accessToken: string): Promise<Blob> => {
        try {
            // Encode texts and file to base64
            const base64ParaphrasedText = ParaphraserService.encodeUnicodeToBase64(paraphrasedText);
            const base64EncodedFile = await ParaphraserService.encodeFileToBase64(file);

            // Prepare the request body
            const body: IDownloadParaphrasedDocRequest = {
                paraphrasedText: base64ParaphrasedText,
                file: base64EncodedFile,
            };

            // Send the request
            return await this.getResponseAsync<Blob>(
                {
                    commandPath: `Paraphrase/downloadParaphrasedDoc`,
                    method: 'POST',
                    body: body,
                },
                accessToken,
            );
        } catch (error) {
            console.error(`Error downloading paraphrased document:`, error);
            throw error; // Re-throw to allow caller to handle
        }
    };

    private static async encodeFileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const result = reader.result as string;
                resolve(result.split(',')[1]);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    }

    /**
     * Encodes a Unicode string to a Base64 string.
     * @param str - The input string to encode.
     * @returns The Base64-encoded string.
     */
    private static encodeUnicodeToBase64 = (str: string): string => {
        // Convert the string to a Uint8Array using UTF-8 encoding
        const utf8Bytes = new TextEncoder().encode(str);

        // Convert the Uint8Array to a binary string
        let binary = '';
        utf8Bytes.forEach((byte) => (binary += String.fromCharCode(byte)));

        // Encode the binary string to Base64
        return window.btoa(binary);
    };
}
