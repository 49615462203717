import { useMsal } from '@azure/msal-react';
import {
    Button,
    Spinner,
    TabValue,
    Tooltip,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import {
    ArrowDownload32Regular,
    Checkmark32Regular,
    ClipboardPaste24Regular,
    Copy32Regular,
    Delete24Regular,
    DocumentArrowUp20Regular,
    DocumentOnePageSparkle24Regular,
    Sparkle28Filled,
} from '@fluentui/react-icons';
import { diffWords } from 'diff';
import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Constants } from '../../../Constants';
import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { useFile } from '../../../libs/hooks';
import { AlertType } from '../../../libs/models/AlertType';
import { TemperatureType } from '../../../libs/models/TemperatureType';
import { DocumentImportService } from '../../../libs/services/DocumentImportService';
import { ParaphraserService } from '../../../libs/services/ParaphraserService';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { addAlert } from '../../../redux/features/app/appSlice';
import {
    setParaphrasedText,
    setParaphraserIsGenerating,
    setParaphraserOriginalText,
    setParaphraserState,
} from '../../../redux/features/conversations/conversationsSlice';
import { StylesTab } from '../../paraphraser/StylesTab';

const useClasses = makeStyles({
    root: {
        ...shorthands.outline('none'),
        // ...shorthands.margin('5px'),
        // flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        // width: '100%',
        height: '100vh',
    },

    container: {
        // ...shorthands.margin(tokens.spacingVerticalL, 'auto'),
        ...shorthands.outline('none'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        minHeight: '30',
        width: '100%',
        height: '100%',
        ...shorthands.flex(1),
        // boxShadow: tokens.shadow16,
    },
    textBoxHeaderTitle: {
        ...shorthands.margin(0),
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: '1.1rem',
        ':span': {
            marginLeft: '2px',
        },
    },

    textArea: {
        ...shorthands.flex(1),
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
        ...shorthands.border('none'),
        ...shorthands.outline('none'),
        ...shorthands.overflow('auto'),
        flexGrow: 1,
        backgroundColor: 'white',
        lineHeight: '1.5',
        fontFamily: tokens.fontFamilyBase,
        resize: 'none',
        fontSize: '1.1rem',
        scrollbarWidth: 'thin',
        scrollbarColor: `${tokens.colorBrandBackground} transparent`,
        '::-webkit-scrollbar': {
            width: '12px',
        },
        '::-webkit-scrollbar-thumb': {
            ...shorthands.borderRadius(tokens.borderRadiusLarge),
            backgroundClip: 'content-box',
        },
    },
    textBoxHeader: {
        ...shorthands.padding(tokens.spacingVerticalM),
        ...shorthands.borderBottom('1px', 'solid', '#ddd'),
        display: 'flex',
        height: '2rem',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
    },
    resultBoxHeader: {
        ...shorthands.gap('0px'),
        color: tokens.colorBrandBackground,
    },

    uploadButton: {
        width: 'content-fit',
    },
    buttonSection: {
        ...shorthands.borderTop('1px', 'solid', '#ddd'),
        ...shorthands.padding('15px', '0px'),
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
    },
    bottomSection: {
        ...shorthands.padding('10px'),
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: tokens.colorNeutralBackground1,
    },
    rtl: {
        textAlign: 'left',
        direction: 'ltr',
    },
    ltr: {
        textAlign: 'right',
        direction: 'rtl',
    },
    spinnerBox: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
        ...shorthands.border('none'),
        ...shorthands.outline('none'),
        ...shorthands.overflow('auto'),
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        resize: 'none',
        height: '40vh',
        backgroundColor: 'white',
        scrollbarWidth: 'thin',
        scrollbarColor: `${tokens.colorBrandBackground} transparent`,
        '::-webkit-scrollbar': {
            width: '12px',
        },
        '::-webkit-scrollbar-thumb': {
            ...shorthands.borderRadius(tokens.borderRadiusLarge),
            backgroundClip: 'content-box',
        },
    },
});

export const ParaphraserTab: React.FC = () => {
    const classes = useClasses();
    const fileHandler = useFile();
    const { instance, inProgress } = useMsal();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chatState = conversations[selectedId];
    const { originalText, paraphrasedText, isGenerating } = chatState.paraphraserState;
    const dispatch = useDispatch();
    const [copied, setCopied] = React.useState(false);
    const [deleted, setDeleted] = React.useState(false);
    const [isWordDocUploaded, setIsWordDocUploaded] = React.useState(false);
    const [isDocumentDownloading, setIsDocumentDownloading] = React.useState(false);
    const [pasted, setPasted] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState<TabValue>('typos');
    const [isFileUploading, setIsFileUploading] = React.useState(false);
    // const [isDocuementReturned, setIsDocuementReturned] = React.useState(false);
    // const [selectedText, setSelectedText] = React.useState('');
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    const percentUsed = Math.round(((activeUserInfo?.budgetUsed ?? 1) / (activeUserInfo?.budgetAllocated ?? 1)) * 100);
    const temperature = conversations[selectedId].temperature as TemperatureType;

    const paraphraserService = new ParaphraserService();
    const documentImportService = new DocumentImportService();
    const localDocumentFileRef = useRef<HTMLInputElement | null>(null);

    /**
     * Handles the change event of a text area element and updates the original text in the paraphraser state.
     *
     * @param {ChangeEvent<HTMLTextAreaElement>} event - The change event object containing the current target value.
     * @return {void} This function does not return anything.
     */
    const onTextChange: ChangeEventHandler<HTMLTextAreaElement> = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        dispatch(
            setParaphraserOriginalText({
                chatId: selectedId,
                originalText: event.currentTarget.value,
            }),
        );
    };

    /**
     * Handles the file upload event and uploads the file to the server. If the file is a Word document,
     * it will be uploaded directly. Otherwise, it will be converted to plain text and stored in the
     * paraphraser state. If the file is too large, it will not be uploaded and the user will be alerted.
     *
     * @param {string} selectedId - The ID of the chat to which the file belongs.
     * @return {Promise<void>} A promise that resolves when the file has been uploaded.
     */
    const onFileUpload = async (selectedId: string): Promise<void> => {
        if (!localDocumentFileRef.current?.files) {
            return;
        }
        setIsFileUploading(true);
        try {
            const file = localDocumentFileRef.current.files[0];
            if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                setIsWordDocUploaded(true);
            }
            const content = await documentImportService.getDocumentContentAsync(
                [file],
                await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                false,
            );
            dispatch(setParaphraserOriginalText({ chatId: selectedId, originalText: content }));
            void fileHandler.getDocumentContent(selectedId, localDocumentFileRef);
        } catch (error) {
            console.error('File upload error:', error);
        } finally {
            setIsFileUploading(false);
        }
    };

    const onDownloadFile = async () => {
        if (!localDocumentFileRef.current?.files) {
            return;
        }

        const file = localDocumentFileRef.current.files[0];
        try {
            // Retrieve the access token
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

            // Indicate that the download is in progress
            setIsDocumentDownloading(true);

            // Download the paraphrased document
            const finalFile = await paraphraserService.downloadParaphrasedDoc(
                cleanHtmlText(removeDeletedSpan(paraphrasedText)),
                file,
                accessToken,
            );

            // Create a download URL for the file
            const downloadUrl = URL.createObjectURL(finalFile);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = file.name;
            document.body.appendChild(link); // Append to the DOM
            link.click();
            document.body.removeChild(link); // Clean up

            // Optionally, revoke the object URL after download
            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Error downloading the file:', error);
            // You can also set an error state here to display a message to the user
            // setError('Failed to download the document. Please try again later.');
        } finally {
            // Ensure that the downloading state is reset regardless of success or failure
            setIsDocumentDownloading(false);
        }
    };

    /**
     * Determines if the given text contains any characters belonging to the Hebrew, Arabic, or other RTL scripts.
     *
     * @param {string} text - The text to check for RTL characters.
     * @return {boolean} Returns true if the text contains any RTL characters, false otherwise.
     */
    const isRtlText = (text: string): boolean => {
        if (text.length < 1) {
            return true;
        }
        // Regular expression covering Hebrew, Arabic, and other RTL scripts
        const rtlChar = /[\u0590-\u05FF\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/;

        return rtlChar.test(text);
    };

    /**
     * Copies the cleaned text from the `paraphrasedText` state to the clipboard,
     * removing any HTML tags. After copying, sets the `copied` state to `true`
     * and then sets it back to `false` after 1 second using `setTimeout`.
     *
     * @return {void} This function does not return anything.
     */
    const onCopy = (): void => {
        const textWithoutHTML = cleanHtmlText(removeDeletedSpan(paraphrasedText));
        void navigator.clipboard.writeText(textWithoutHTML);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    /**
     * Removes <span> elements with specific styles and their inner text from the provided HTML string.
     *
     * @param htmlText - The HTML string to clean.
     * @returns A cleaned HTML string with specified <span> elements and their content removed.
     */
    const removeDeletedSpan = (htmlText: string): string => {
        return (
            htmlText
                // Remove <span> tags with exact style "color: red; text-decoration: line-through;" and their content
                .replace(/<span\s+style="color:\s*red;\s*text-decoration:\s*line-through;">[\s\S]*?<\/span>/gi, '')
                .replace(/\s+/g, ' ')
                .trim()
        );
    };

    const cleanHtmlText = (htmlText: string): string => {
        return (
            htmlText
                // Remove <span> tags and their attributes
                .replace(/<\/?span[^>]*>/g, ' ')
                // Remove all other HTML tags
                .replace(/<[^>]+>/g, ' ')
                // Replace all whitespace sequences (including newlines, tabs, etc.) with a single space
                .replace(/\s+/g, ' ')
                // Trim any leading or trailing spaces
                .trim()
        );
    };

    /**
     * Handles the deletion of the paraphraser state by dispatching an action to update the state with empty values for original text, paraphrased text, and isGenerating flag.
     * After 1 second, sets the deleted state to false.
     *
     * @return {void} This function does not return anything.
     */
    const onDelete = (): void => {
        setDeleted(true);

        if (localDocumentFileRef.current) {
            const originalOnChange = localDocumentFileRef.current.onchange;
            localDocumentFileRef.current.onchange = null;
            localDocumentFileRef.current.value = '';

            dispatch(
                setParaphraserState({
                    id: selectedId,
                    paraphraserState: {
                        originalText: '',
                        paraphrasedText: '',
                        isGenerating: false,
                    },
                }),
            );

            setTimeout(() => {
                if (localDocumentFileRef.current) {
                    localDocumentFileRef.current.onchange = originalOnChange;
                }
            }, 0);
        }
        setIsWordDocUploaded(false);
        setTimeout(() => {
            setDeleted(false);
        }, 1000);
    };

    const onPaste = () => {
        void navigator.clipboard.readText().then((text) => {
            dispatch(
                setParaphraserOriginalText({
                    chatId: selectedId,
                    originalText: text,
                }),
            );
            setPasted(true);
            setTimeout(() => {
                setPasted(false);
            }, 1000);
        });
    };

    const countWordsIgnoringSpan = (text: string): number => {
        if (!text) {
            return 0;
        }
        const textWithoutHTML = cleanHtmlText(text);
        const words = textWithoutHTML.split(/\s+|\n+/);
        return words.length;
    };

    useEffect(() => {
        const getDiffHTML = (originalText: string, paraphrasedText: string): string => {
            if (isParaphrased(paraphrasedText)) {
                return paraphrasedText;
            }
            const dif = diffWords(originalText, paraphrasedText);
            let str = '';
            dif.forEach((d) => {
                if (d.removed) {
                    // Deleted text: red and struck-through
                    str += `<span style="color: red; text-decoration: line-through;">${d.value}</span>`;
                } else if (d.added) {
                    // Inserted text: blue and underlined
                    str += `<span style="color: blue; text-decoration: underline;">${d.value}</span>`;
                } else {
                    // Unchanged text
                    str += d.value;
                }
            });
            return str.replace(/\n/g, '<br>');
        };
        if (!isGenerating && !isParaphrased(paraphrasedText)) {
            if (selectedTab !== 'translator' && originalText.length > 0) {
                dispatch(
                    setParaphrasedText({
                        chatId: selectedId,
                        paraphrasedText: getDiffHTML(originalText, paraphrasedText),
                    }),
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGenerating]);

    const isParaphrased = (text: string) => {
        const paraphrasedRegex =
            /<span\s+style=['"]?(?:color:\s?(?:red|blue);?\s*text-decoration:\s?(?:line-through|underline);?|text-decoration:\s?(?:line-through|underline);?\s*color:\s?(?:red|blue);?)['"]?>/i;
        return paraphrasedRegex.test(text);
    };

    /**
     * Submits the form and triggers the paraphrasing process.
     *
     * @return {Promise<void>} A promise that resolves when the paraphrasing process is complete.
     */
    const onSubmit = async (): Promise<void> => {
        dispatch(setParaphraserIsGenerating({ chatId: selectedId, isGenerating: true }));

        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            if (!originalText) return;
            let file: File | undefined;
            const uploadedFile = localDocumentFileRef.current?.files?.[0];

            if (uploadedFile?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                file = uploadedFile;
            }

            await paraphraserService.getParaphrasedText(
                accessToken,
                cleanHtmlText(originalText),
                selectedTab as string,
                selectedId,
                temperature,
                file,
            );
            // if (resultDocument) {
            //     const finalFileName = file?.name ?? 'paraphrased.docx';
            //     const finalFile = new File([resultDocument], finalFileName, {
            //         type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            //         lastModified: Date.now(),
            //     });
            //     const downloadUrl = URL.createObjectURL(finalFile);
            //     const link = document.createElement('a');
            //     link.href = downloadUrl;
            //     link.download = finalFileName;
            //     link.click();

            //     URL.revokeObjectURL(downloadUrl);

            //     setIsDocuementReturned(true);
            //     console.log('Document returned:', isDocuementReturned);
            // }
        } catch (error) {
            console.error('Error during paraphrasing:', error);

            // Display an error message to the user
            dispatch(
                addAlert({
                    message: 'An error occurred during paraphrasing. Please try again.',
                    type: AlertType.Error,
                }),
            );
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div style={{ display: 'flex', width: '100%', flex: 1 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <div className={classes.textBoxHeader}>
                            <StylesTab selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                        </div>
                        <textarea
                            value={originalText}
                            className={`${classes.textArea} ${
                                isRtlText(cleanHtmlText(originalText).substring(0, 8).trim())
                                    ? classes.rtl
                                    : classes.ltr
                            }`}
                            style={{ outline: 'none' }}
                            onChange={onTextChange}
                        />
                        <div className={classes.bottomSection}>
                            כמות מילים:{' '}
                            {originalText.trim().length ? originalText.split(/\s+|\n+/).filter(Boolean).length : 0}
                            <div>
                                <input
                                    type="file"
                                    ref={localDocumentFileRef}
                                    style={{ display: 'none' }}
                                    accept={Constants.app.getCotentTypes}
                                    multiple={false}
                                    onChange={() => {
                                        void onFileUpload(selectedId);
                                    }}
                                />
                                <Tooltip content="העלאת מסמך" relationship="label">
                                    <Button
                                        className={classes.uploadButton}
                                        icon={
                                            isFileUploading ? (
                                                <Spinner size="extra-small" />
                                            ) : (
                                                <DocumentArrowUp20Regular />
                                            )
                                        }
                                        disabled={percentUsed >= 100}
                                        appearance="subtle"
                                        onClick={() => localDocumentFileRef.current?.click()}
                                    />
                                </Tooltip>
                                {deleted ? (
                                    <Tooltip content={'אופס'} relationship="label">
                                        <Button
                                            icon={<Checkmark32Regular />}
                                            color="primary"
                                            appearance="transparent"
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip content={'איפוס שדות'} relationship="label">
                                        <Button appearance="subtle" icon={<Delete24Regular />} onClick={onDelete} />
                                    </Tooltip>
                                )}
                                {pasted ? (
                                    <Tooltip content={'הודבק'} relationship="label">
                                        <Button
                                            icon={<Checkmark32Regular />}
                                            color="primary"
                                            appearance="transparent"
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip content={'הדבקה'} relationship="label">
                                        <Button
                                            appearance="subtle"
                                            icon={<ClipboardPaste24Regular />}
                                            onClick={onPaste}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <div className={mergeClasses(classes.textBoxHeader, classes.resultBoxHeader)}>
                            <div className={classes.textBoxHeaderTitle}>
                                <DocumentOnePageSparkle24Regular />
                                <span>תוצאה</span>
                            </div>
                        </div>
                        <div
                            className={`${classes.textArea} ${
                                isRtlText(paraphrasedText.replace(/<[^>]*>/g, '').substring(0, 20))
                                    ? classes.rtl
                                    : classes.ltr
                            }`}
                            style={{
                                outline: 'none',
                                borderRight: '1px solid #ddd',
                                backgroundColor: tokens.colorNeutralBackground1,
                            }}
                            dangerouslySetInnerHTML={{
                                __html: paraphrasedText.replace(/\n/g, '<br>'),
                            }}
                        />

                        <div className={classes.bottomSection} style={{ borderRight: '1px solid #ddd' }}>
                            כמות מילים: {countWordsIgnoringSpan(paraphrasedText)}
                            <div style={{ display: 'flex' }}>
                                {isWordDocUploaded &&
                                    !isGenerating &&
                                    selectedTab !== 'translator' &&
                                    isParaphrased(paraphrasedText) &&
                                    (isDocumentDownloading ? (
                                        <Spinner size="extra-small" />
                                    ) : (
                                        <Tooltip content={'הורדת המסמך'} relationship="label">
                                            <Button
                                                icon={<ArrowDownload32Regular />}
                                                color="primary"
                                                onClick={() => void onDownloadFile()}
                                                appearance="subtle"
                                            />
                                        </Tooltip>
                                    ))}
                                {copied ? (
                                    <Tooltip content={'הועתק'} relationship="label">
                                        <Button
                                            icon={<Checkmark32Regular />}
                                            color="primary"
                                            appearance="transparent"
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip content={'העתקה'} relationship="label">
                                        <Button appearance="subtle" icon={<Copy32Regular />} onClick={onCopy} />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.buttonSection}>
                    <style>
                        {`
          @keyframes rotate {
            from {
              transform: rotate(0deg);

            }
            to {
              transform: rotate(360deg)
            }
          }
        `}
                    </style>
                    <Button
                        appearance="primary"
                        shape="circular"
                        disabled={(originalText.length === 0 && !isWordDocUploaded) || percentUsed >= 100}
                        size="large"
                        onKeyDown={(e) => e.key === 'Enter' && void onSubmit()}
                        icon={
                            <Sparkle28Filled
                                style={
                                    !isGenerating ? { animation: 'none' } : { animation: 'rotate 2s infinite linear' }
                                }
                            />
                        }
                        onClick={!isGenerating ? () => void onSubmit() : () => {}}
                    >
                        ניסוח
                    </Button>
                </div>
            </div>
        </div>
    );
};
