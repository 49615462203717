import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { Constants } from './Constants';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: Constants.APPLICATION_INSIGHTS_STRING as string,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        loggingLevelConsole: 1, // Debug level for verbose logging in console
        disableFetchTracking: false, // Track all outgoing HTTP requests
        enableCorsCorrelation: true, // Correlate telemetry across services
        distributedTracingMode: 1, // Enables W3C distributed tracing
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});

appInsights.loadAppInsights();

export { appInsights, reactPlugin };
