import { makeStyles, shorthands } from '@fluentui/react-components';
import { FC } from 'react';
import { IPickerItemProps, PickerItem } from './PickerItem';

const usePickerStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        ...shorthands.gap('20px'),
        ...shorthands.padding('10px'),
        overflowX: 'auto',
    },
});

export interface IPickerGridProps {
    items: IPickerItemProps[];
}

export const PickerGrid: FC<IPickerGridProps> = ({ items }) => {
    const styles = usePickerStyles();

    return (
        <div className={styles.container}>
            {items.map((item, index) => (
                <PickerItem key={index} {...item} />
            ))}
        </div>
    );
};
